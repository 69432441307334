import Header from "../../../components/partials/dashboard/HeaderStyle/header";

const AccountSettings = () => {
	return (
		<div>
			<Header />
			Account Settings
		</div>
	)
}

export default AccountSettings;